@forward '@el8/vital/dist/generated/tokens/vds-tokens';

$theme-primary: #1c94bc;
$theme-success: #10c469;
$theme-warning: #f9c851;
$theme-danger: #ff5b5b;
$theme-pink: #ff8acc;
$theme-purple: #5b69bc;
$theme-inverse: #3b3e47;
$theme-dark: #282828;
$theme-bg: #e6e6e6;

$themeColors: (
  'primary': $theme-primary,
  'success': $theme-success,
  'warning': $theme-warning,
  'danger': $theme-danger,
  'pink': $theme-pink,
  'purple': $theme-purple,
  'inverse': $theme-inverse,
  'dark': $theme-dark,
);

$primary-font:
  400 0.875rem/1.6 'Open Sans',
  'Helvetica Neue',
  Helvetica,
  sans;
$primary-font-strong:
  600 0.875rem/1.6 'Open Sans',
  'Helvetica Neue',
  Helvetica,
  sans;

$large-font:
  400 1rem/1.6 'Open Sans',
  'Helvetica Neue',
  Helvetica,
  sans;
$large-font-strong:
  600 1rem/1.6 'Open Sans',
  'Helvetica Neue',
  Helvetica,
  sans;
